<template>
  <b-tabs
    v-model="tabIndex"
    content-class="mt-1"
  >
    <!-- This tabs content will always be mounted -->
    <b-tab
      title="Historial"
      lazy
    >
      <sale-list
        :key="saleListKey"
        @addSaleTab="newTab($event)"
      />
    </b-tab>

    <!-- This tabs content will not be mounted until the tab is shown -->
    <!-- and will be un-mounted when hidden -->
    <b-tab
      title="Nueva"
      lazy
    >
      <sale-add
        @changeTab="changeTab($event)"
        @addSaleTab="newTab($event)"
      />
    </b-tab>

    <!-- Dynamic tab for sale view -->
    <b-tab
      v-for="sale in salesTabs"
      :key="'sale-tab-' + sale"
      :title="'Venta #' + sale"
      lazy
    >
      <sale-view :sale-id="sale" />
    </b-tab>

    <!-- Close tab button -->
    <template
      v-if="salesTabs.length > 0"
      #tabs-end
    >
      <b-nav-item
        @click.prevent="closeTab(salesTabs[0])"
      >
        <b>x</b>
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
import SaleList from './SalesList.vue'
import SaleAdd from './SalesAdd.vue'
import SaleView from './SaleView.vue'

export default {
  components: {
    SaleList,
    SaleAdd,
    SaleView,
  },

  data() {
    return {
      tabIndex: 1,
      saleListKey: 0,
      salesTabs: [],

      cashData: {},
    }
  },

  methods: {
    changeTab(tabIndex) {
      this.tabIndex = tabIndex // Change tab
      this.saleListKey += 1 // Re-Render component
    },

    newTab(saleId) {
      this.salesTabs = []
      this.salesTabs.push(saleId)
      setTimeout(() => {
        this.tabIndex = 2
      }, 100)
    },

    closeTab(x) {
      for (let i = 0; i < this.salesTabs.length; i += 1) {
        if (this.salesTabs[i] === x) {
          this.salesTabs.splice(i, 1)
        }
      }
      this.tabIndex = 0 // Redirecto to list
    },
  },
}
</script>
