<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          class="invoice-preview-card"
          no-body
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-alert
                  v-if="anulated"
                  variant="danger"
                  show
                >
                  <h4 class="alert-heading">
                    Anulada
                  </h4>
                  <div class="alert-body">
                    <span>Esta venta ha sido anulada.</span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

            <b-row>
              <!-- Col: Cliente -->
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1 mt-1">
                  Cliente:
                </h6>
                {{ saleData.customer }}
              </b-col>

              <!-- Col: Fecha -->
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1 mt-1">
                  Fecha de venta:
                </h6>
                {{ saleData.sale_date }}
              </b-col>

              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1">
                  Vendido por:
                </h6>
                {{ saleData.user }}
              </b-col>

            </b-row>
          </b-card-body>

          <b-card-body class="invoice-padding form-item-section">
            <b-table
              responsive="sm"
              :items="saleData.products"
              :fields="SaleFields"
            />

            <div
              v-if="saleData.summaryInfo[0]"
              class="float-right mr-4"
            >
              Total:
              <h4 class="sale-total-amount">
                Q.{{ saleData.summaryInfo[0].total }}
              </h4>
            </div>
          </b-card-body>

          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1">
                  Forma de pago
                </h6>
                <span v-if="saleData.payment_type">{{ saleData.payment_type.toUpperCase() }}</span>
              </b-col>
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1">
                  Tipo de venta
                </h6>
                {{ (saleData.sale_type === 'local') ? 'En Tienda' : 'Envío' }}
              </b-col>
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1">
                  Dirección de entrega:
                </h6>
                {{ (saleData.delivery_address !== '') ? saleData.delivery_address : 'No aplica' }}
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1">
                  Ingresada al sistema el:
                </h6>
                {{ saleData.created_at }}
              </b-col>
            </b-row>

          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Observaciones: </span>
            {{ saleData.comments }}
          </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card v-if="!anulated">
          <!-- Button: cancel -->
          <b-button
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-danger"
            block
            @click="saleCancel"
          >
            Anular
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-info"
            block
            @click="printTicket"
          >
            Imprimir
          </b-button>

          <div
            v-if="showPrint"
            class="mt-3"
          >
            <div
              id="printMe"
              class="ticket"
            >
              <!-- <img
                src="@/assets/images/logo/logo_print.svg"
                alt="Logotipo"
                class="mb-1"
              > -->
              <p class="centrado">Kultiver S.A.
                <br>Guatemala
                <br>{{ getCurrentDateTime() }}
                <br>Cliente: {{ saleData.customer }}</p>
              <table>
                <thead>
                  <tr>
                    <th class="cantidad">
                      CANTIDAD
                    </th>
                    <th class="producto">
                      PRODUCTO
                    </th>
                    <th class="precio">
                      TOTAL
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in saleData.products"
                    :key="item.product_id"
                  >
                    <td class="cantidad">
                      {{ item.qty }} X Q.{{ item.price }}
                    </td>
                    <td class="producto">
                      {{ item.product }}
                    </td>
                    <td class="precio">
                      {{ item.qty * item.price }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      class="text-right"
                    >
                      <hr>
                      <b class="total_size">TOTAL: Q.{{ saleData.summaryInfo[0].total }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                v-if="saleData.comments !== '-'"
                class="mt-1"
              >
                NOTA: {{ saleData.comments }}
              </p>
              <p
                class="centrado mt-2"
              >
                ¡GRACIAS POR SU COMPRA!
              </p>
            </div>
          </div>

        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {

  directives: {
    Ripple,
  },

  props: {
    saleId: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      saleData: {
        customer_id: '',
        customer: '',
        sale_date: '',
        comments: '',
        products: [],
        summaryInfo: [],
        created_at: '',
        user: '',
      },

      SaleFields: [
        { key: 'product', label: 'Producto' },
        { key: 'qty', label: 'Cantidad' },
        { key: 'price', label: 'Precio' },
        { key: 'total', variant: 'success' },
      ],

      anulated: false,
      showPrint: false,
    }
  },

  mounted() {
    if (this.saleId > 0) {
      this.getSale()
    }
  },

  methods: {
    getSale() {
      axios
        .get(`sale/${this.saleId}`)
        .then(response => {
          this.saleData.customer = response.data.data.customer
          this.saleData.customer_id = response.data.data.customer_id
          this.saleData.sale_date = response.data.data.sale_date
          this.saleData.comments = (response.data.data.comments) ? response.data.data.comments : '-'
          this.saleData.products = response.data.data.products
          this.saleData.summaryInfo = response.data.data.summaryInfo
          this.saleData.created_at = response.data.data.created_at
          this.saleData.user = response.data.data.user

          this.saleData.payment_type = response.data.data.payment_type
          this.saleData.payment_document = response.data.data.payment_document
          this.saleData.sale_type = response.data.data.sale_type
          this.saleData.delivery_address = response.data.data.delivery_address

          if (response.data.data.status === 'Inactive') {
            this.anulated = true
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    saleCancel() {
      this.$bvModal
        .msgBoxConfirm('Esta acción no se puede revertir. Cancelará el documento y devolverá el inventario de los productos.', {
          title: '¿Anular la venta?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .put(`sale/cancel/${this.saleId}`)
              .then(response => {
                if (response.data.res) {
                  this.makeToast('success', 'Venta anulada', `Se anuló la venta, transacción: #${response.data.data.id}.`)
                  this.anulated = true
                } else {
                  throw (response)
                }
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    getCurrentDateTime() {
      const date = new Date()
      const dateStr = `${(`00${date.getMonth() + 1}`).slice(-2)}/${
        (`00${date.getDate()}`).slice(-2)}/${
        date.getFullYear()} ${
        (`00${date.getHours()}`).slice(-2)}:${
        (`00${date.getMinutes()}`).slice(-2)}:${
        (`00${date.getSeconds()}`).slice(-2)}`

      return dateStr
    },

    async printTicket() {
      // Pass the element id here
      this.showPrint = true
      await new Promise(resolve => { window.setTimeout(resolve, 500) })
      await this.$htmlToPaper('printMe')
    },
  },
}
</script>

<style lang="scss">

td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.producto,
th.producto {
    width: 145px;
    max-width: 145px;
}

td.cantidad,
th.cantidad {
    width: 110px;
    max-width: 110px;
    word-break: break-all;
}

td.precio,
th.precio {
    width: 70px;
    max-width: 70px;
    word-break: break-all;
}

.centrado {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 240px;
    max-width: 240px;
    font-size: 10px;
}

img {
    max-width: inherit;
    width: inherit;
}

.total_size {
  font-size:15px
}
</style>
