<template>
  <section class="invoice-add-wrapper">

    <validation-observer ref="simpleRules">
      <b-form
        v-if="show"
      >
        <b-row class="invoice-add">
          <!-- Pedido -->
          <b-col
            sm="12"
            md="12"
            xl="12"
          >
            <b-card
              class="sale-add-wrapper"
              no-body
            >
              <b-card-body>
                <b-row>
                  <!-- Col: cliente -->
                  <b-col
                    cols="12"
                    xl="6"
                    md="6"
                  >
                    <h6 class="mb-1 mt-1">
                      Cliente:
                    </h6>
                    <validation-provider
                      v-slot="{ errors }"
                      name="cliente"
                      rules="required"
                    >
                      <b-form-group>
                        <v-select
                          v-if="customersOptions"
                          v-model="saleData.customer"
                          :options="customersOptions"
                          input-id="select-customers"
                          :reduce="customer => customer"
                          :clearable="true"
                          placeholder="Selecciona el cliente"
                          required
                          @search="onSearchCustomers"
                          @input="val => setCustomerData(val)"
                        >
                          <template #list-header>
                            <li
                              class="add-new-customer-header d-flex align-items-center my-50"
                              @click="customerAddSidebarActive = true"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Nuevo cliente</span>
                            </li>
                          </template>
                          <template slot="no-options">
                            Lo siento, no se encontraron clientes
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Col: Fecha de cotización -->
                  <b-col
                    cols="12"
                    xl="3"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="fecha"
                      rules="required"
                    >
                      <b-form-group>
                        <h6 class="mb-1 mt-1">
                          Fecha:
                        </h6>
                        <b-form-datepicker
                          v-model="saleData.sale_date"
                          class="form-control invoice-edit-input"
                          placeholder="Fecha de la cotización"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="es-GT"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Col: Fecha de validez -->
                  <b-col
                    cols="12"
                    xl="3"
                    md="3"
                  >
                    <b-form-group>
                      <h6 class="mb-1 mt-1">
                        Válida hasta:
                      </h6>
                      <b-form-datepicker
                        id="ex-disabled-readonly"
                        v-model="saleData.sale_date"
                        class="form-control invoice-edit-input"
                        placeholder="Cotización válida hasta"
                        locale="es-GT"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row
                  class="mt-1"
                  style="font-size:12px"
                >
                  <b-col
                    cols="12"
                    xl="6"
                    md="6"
                  >
                    <b-badge
                      style="margin-bottom: 8px;"
                      variant="secondary"
                    >
                      <span>Datos del cliente</span>
                    </b-badge><br>

                    <strong>Nombre: </strong> Jorge Chacón<br>
                    <strong>Nombre comercial: </strong> AGRO EXPORTADORA DE VEGETALES, S.A (AGROVEGSA)<br>
                    <strong>Teléfono: </strong> 5302-1498 / 77257057<br>
                    <strong>Correo: </strong> jchacon@gmail.com<br>
                    <strong>Dirección: </strong> Lote 336B Jardines de Santiago, Santiago Sacatepequez, Sacatepequez<br>
                    <strong>Crédito: </strong> 30 días<br>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                    md="6"
                    class="margin_top_mobile"
                  >
                    <b-badge
                      style="margin-bottom: 8px;"
                      variant="secondary"
                    >
                      <span>Datos del vendedor</span>
                    </b-badge><br>

                    <strong>Nombre: </strong> Erick Ovando<br>
                    <strong>Teléfono: </strong> 35228670<br>
                    <strong>Correo: </strong> flopez@kultiver.com<br>
                  </b-col>
                </b-row>
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row>
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <b-col
                      v-if="saleData.products.length > 0"
                      cols="12"
                    >
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="4"
                          >
                            <strong>Producto</strong>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <strong>Precio</strong>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <strong>Cantidad</strong>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <strong>Suma</strong>
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>
                    </b-col>
                  </b-row>

                  <b-row
                    v-for="(product, index) in saleData.products"
                    :key="index"
                    ref="row"
                    class="pb-1"
                  >
                    <!-- Item Form -->
                    <b-col cols="12">

                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 pt-1 pl-1 pr-1">
                          <!-- Form inputs -->

                          <!-- Producto input -->
                          <b-col
                            cols="12"
                            lg="4"
                          >
                            <label class="d-inline d-lg-none">Producto</label>
                            <v-select
                              :options="productsOptions"
                              :reduce="product => product"
                              input-id="select-products"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Seleccione producto"
                              @search="onSearchProducts"
                              @input="val => updateItem(index, val)"
                            >
                              <template slot="no-options">
                                Lo siento, no se encontraron productos
                              </template>
                            </v-select>
                          </b-col>

                          <!-- Precio input -->
                          <b-col
                            cols="12"
                            lg="2"
                            md="6"
                          >
                            <label class="d-inline d-lg-none">Precio</label>
                            <b-form-input
                              v-model="product.price"
                              type="number"
                              min="0.01"
                              step="any"
                              class="mb-2"
                            />
                          </b-col>

                          <!-- Cantidad input -->
                          <b-col
                            cols="12"
                            lg="2"
                            md="6"
                          >
                            <label class="d-inline d-lg-none">Cantidad</label>
                            <b-form-input
                              v-model="product.quantity"
                              type="number"
                              min="1"
                              step="any"
                              class="mb-2"
                              @change="validateProductStock(product)"
                            />
                          </b-col>

                          <!-- Suma -->
                          <b-col
                            cols="12"
                            lg="3"
                            md="12"
                          >
                            <label class="d-inline d-lg-none">Suma</label>
                            <p class="mb-1">
                              Q.{{ Number(product.price * product.quantity).toFixed(2) }}
                            </p>
                          </b-col>
                        </b-row>

                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <b-row>
                  <b-button
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    variant="outline-warning"
                    size="sm"
                    style="margin-left: 15px;"
                    @click="addItem"
                  >
                    Agregar producto
                  </b-button>
                </b-row>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                  >
                    <h4>
                      Subtotal: <strong>Q.{{ saleTotal }}</strong>
                    </h4>

                    <span>% Descuento</span><br>
                    <span>Total descuento</span><br>
                    <span>Costo de flete</span><br>
                    <span>Otros</span><br>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Notes -->
              <b-card-body
                class="invoice-padding pt-0"
                style="font-size: 12px"
              >
                <p><strong>Nota:</strong> Los precios están sujetos a variaciones por incrementos en las materias primas,estas variaciones se comunicarán previamente al comprador. En caso que el volúmen solicitado supere las 2000 unidades mensuales, los precios ofertados pueden mejorar</p>
                <p>
                  <strong>
                    <span class="text-danger">
                      Depósitos y transferencias en las cuentas monetarias:
                    </span>
                    <ul style="margin-left:-20px">
                      <li>Banco Banrural N°: 3540026761</li>
                      <li>Banco Industrial N°: 126-015764-7</li>
                    </ul>
                  </strong>
                </p>
              </b-card-body>

              <!-- Observs -->
              <b-card-body class="invoice-padding pt-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <span class="font-weight-bold">Observaciones: </span>
                    <b-form-textarea
                      v-model="saleData.comments"
                    />
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <!-- Seleccionable formato de cotización -->
                    <span class="font-weight-bold">Formato: </span>
                    <b-form-select
                      v-model="saleData.format"
                      :options="formats"
                      label="Formato de cotización"
                    />
                  </b-col>

                </b-row>
              </b-card-body>

              <b-card-body>
                <b-col
                  cols="12"
                  class="d-flex justify-content-end"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    variant="outline-warning"
                    size="lg"
                    @click="saveData"
                  >
                    Guardar
                  </b-button>
                </b-col>
              </b-card-body>

            </b-card>
          </b-col>
        </b-row>

        <!-- Customer form -->
        <customers-add
          :customer-add-sidebar-active.sync="customerAddSidebarActive"
          @refreshData="getCustomers"
        />

      </b-form>
    </validation-observer>

  </section>
</template>

<script>
import axios from '@axios'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import CustomersAdd from '../../contacts/customers/CustomersAdd.vue'

export default {
  components: {
    vSelect,
    CustomersAdd,
    // flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      // Sale
      saleData: {
        customer_id: '',
        sale_date: new Date().toISOString().slice(0, 10),
        notes: 'Los precios están sujetos a variaciones por incrementos en las materias primas, estas variaciones se comunicarán previamente al comprador. En caso que el volúmen solicitado supere las 2000 unidades mensuales, los precios ofertados pueden mejorar.',
        comments: '',
        payment_type: 'efectivo',
        sale_type: 'local',
        delivery_address: '',
        payment_received: '',
        // products: [],
        products: [JSON.parse(JSON.stringify(
          { // Detail template
            product_id: null,
            price: 0,
            quantity: 0,
          },
        ))],
      },

      payOptions: [
        { text: 'Efectivo', value: 'efectivo' },
        { text: 'Tarjeta', value: 'tarjeta' },
      ],
      saleOptions: [
        { text: 'En Tienda', value: 'local' },
        { text: 'Envío', value: 'envio' },
      ],

      formats: [
        { text: 'Kultiver', value: 'A4' },
        { text: 'Potenz', value: 'A5' },
      ],

      // Detail template
      formProductBlank: {
        product_id: null,
        price: 0,
        quantity: 0,
      },

      // customers
      customersOptions: [],
      customerAddSidebarActive: false,

      // Products
      productsOptions: [],
      productFields: [
        { key: 'label', label: 'Producto' },
        { key: 'price', label: 'Precio' },
        { key: 'stock', label: 'Disp.' },
        { key: 'by_store', label: 'Por tienda' },
      ],
      selectedProducts: [],
      show: true,
      searchQuery: '',
      categoryOptions: [],
      selectedCategory: '',
    }
  },

  computed: {
    saleTotal() {
      let total = 0
      this.saleData.products.forEach(element => {
        total += element.price * element.quantity
      })
      return Number(total).toFixed(2)
    },

    saleChange() {
      // validar que sea efectivo y que el pago sea mayor a cero
      if (this.saleData.payment_type === 'efectivo' && Number(this.saleData.payment_received) > 0) {
        // validar que el pago sea mayor o igual al total
        if (Number(this.saleData.payment_received) >= this.saleTotal) {
          return Number(this.saleData.payment_received - this.saleTotal).toFixed(2)
        }
      }
      return 0
    },
  },

  watch: {
    selectedCategory() {
      this.getProducts()
    },
    searchQuery() {
      this.getProducts()
    },
  },

  mounted() {
    this.initTrHeight()
  },

  created() {
    this.getCustomers()
    this.getProducts()
    this.getCategories()
    window.addEventListener('resize', this.initTrHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    getPaid() {
      if (this.saleData.customer && this.saleData.customer !== '') {
        this.$refs['modal-payment_received'].show()
      } else {
        this.makeToast('danger', 'Falta el cliente', 'Seleccione el cliente')
      }
    },
    hideModal() {
      this.$refs['modal-payment_received'].hide()
    },

    validateProductStock(item) {
      // ejecutar servicio para obtener stock por tienda
      axios
        .get(`product/${item.product_id}`)
        .then(response => {
          // validar si lo requerido es mayor al stock disponible
          if (item.quantity > Number(response.data.data.stock)) {
            this.makeToast('danger', 'Sin Stock', `El artículo: ${item.label}, no tiene stock suficiente.`)
          } else {
            // validar si el articulo tiene stock en la tienda actual
            let stockCurrentStore = 0
            response.data.data.stock_by_store.forEach(element => {
              if (element.store === this.userData().store) {
                stockCurrentStore = element.stock_store
              }
            })
            if (item.quantity > Number(stockCurrentStore)) {
              this.makeToast('danger', 'Sin Stock', `El artículo: ${item.label}, no tiene stock suficiente en la tienda actual.`)
            } else {
              //
            }
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onRowSelected(items) {
      this.selectedProducts = items
      if (items[0].stock > 0) {
        this.addItem()
      } else {
        this.makeToast('danger', 'Sin Stock', `El artículo: ${items[0].label}, no tiene stock suficiente.`)
      }
    },

    getCustomers() {
      this.customersOptions = []
      axios
        .get('customer?perPage=10&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.customersOptions.push({
              label: element.name,
              id: element.id,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchCustomers(search, loading) {
      this.customersOptions = []
      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`customer?filterField=status&filterValue=Y&query=${search}`)
            .then(response => {
              loading(false)
              this.customersOptions = []
              response.data.data.forEach(element => {
                this.customersOptions.push({
                  label: element.name,
                  id: element.id,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getCustomers()
      }
    },

    setCustomerData(customer) {
      if (customer) {
        // get customer
        axios
          .get(`customer/${customer.id}`)
          .then(response => {
            this.saleData.customer_id = response.data.data.id
          })
          .catch(error => {
            this.showErrors(error)
          })
      }
    },

    getProducts() {
      this.productsOptions = []

      const filterArray = [
        { field: 'status', value: 'Y' },
        { field: 'category_id', value: this.selectedCategory || '' },
      ]

      axios
        .get(`product?perPage=100&sortField=id&sortDesc=desc&filters=${JSON.stringify(filterArray)}&query=${this.searchQuery}`)
        .then(response => {
          this.productsOptions = []
          response.data.data.forEach(element => {
            this.productsOptions.push({
              label: element.name,
              id: element.id,
              cost: element.cost,
              price: element.price,
              stock: element.stock,
              by_store: element.stock_by_store,
              description: element.description,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchProducts(search, loading) {
      this.productsOptions = []

      const filterArray = [
        { field: 'status', value: 'Y' },
      ]

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`product?filters=${JSON.stringify(filterArray)}&query=${search}`)
            .then(response => {
              loading(false)
              this.productsOptions = []
              response.data.data.forEach(element => {
                this.productsOptions.push({
                  label: element.name,
                  id: element.id,
                  cost: element.cost,
                  price: element.price,
                  stock: element.stock,
                  description: element.description,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getProducts()
      }
    },

    getCategories() {
      axios
        .get('category?perPage=100&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.categoryOptions.push({
              label: element.name,
              id: element.id,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },
    onSearchCategories(search, loading) {
      this.categoryOptions = []
      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`category?filterField=status&filterValue=Y&query=${search}`)
            .then(response => {
              loading(false)
              this.categoryOptions = []
              response.data.data.forEach(element => {
                this.categoryOptions.push({
                  label: element.name,
                  id: element.id,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getCategories()
      }
    },

    saveData() {
      /*
      // si el tipo de venta es envio y el cliente no tiene direccion
      if (this.saleData.sale_type === 'envio' && this.saleData.delivery_address === '') {
        this.makeToast('danger', 'Error', 'El cliente debe tener una dirección para realizar una venta de envio.')
        return
      }

      axios
        .post('sale', this.saleData)
        .then(async response => {
          if (response.data.res) {
            this.clearForm()
            this.makeToast('success', 'Nuevo ingreso', `Se ha realizado el ingreso correctamente, transacción: #${response.data.data.id}.`)
            await new Promise(resolve => { window.setTimeout(resolve, 500) })
            this.$emit('addSaleTab', response.data.data.id)
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
      */
    },

    clearForm() {
      // Reset our form values
      this.saleData.customer_id = ''
      this.saleData.sale_date = ''
      this.saleData.comments = ''
      this.saleData.products = []
      this.saleData.payment_type = 'efectivo'
      this.saleData.sale_type = 'local'
      this.saleData.delivery_address = ''
      this.saleData.payment_received = ''
      this.searchQuery = ''
      this.selectedCategory = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    userData() {
      return JSON.parse(localStorage.getItem('KultiverAuthUser'))
    },

    // Métodos para manejar productos dinamicamente
    // =======================================================
    addItem() {
      this.$refs.form.style.overflow = 'hidden'
      this.saleData.products.push(JSON.parse(JSON.stringify(this.formProductBlank)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },

    removeItem(index) {
      this.saleData.products.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    updateItem(index, val) {
      const foundItem = this.saleData.products.find(element => element.product_id === val.id)
      if (foundItem) {
        this.makeToast('danger', 'Producto ya seleccionado', `El producto ${val.label} ya fue seleccionado anteriormente, edite la cantidad si es necesario.`)
        this.removeItem(index)
      } else {
        const { id, price, description } = val
        this.saleData.products[index].product_id = id
        this.saleData.products[index].price = price
        this.saleData.products[index].quantity = 1
        this.saleData.products[index].description = description
      }
    },
    // =======================================================
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.sale-add-wrapper {
  .add-new-customer-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.sale-total-wrapper {
  width: 100%;
  max-width: 10rem;
  .sale-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    .sale-total-title {
      margin-bottom: 0.35rem;
    }
    .sale-total-amount {
      margin-bottom: 0.35rem;
      font-weight: 600;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

// aplicar margen cuando sea un celular usando media query
@media (max-width: 991px) {
  .margin_top_mobile {
    margin-top: 1rem;
  }
}
</style>
